///
/// basic carousel formatter
// styles may be shared with hero carousel formatter
///
.carousel-formatter {
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  &.carousel-formatter--full-width {
    max-width: 100%;
  }
  .site-banner__offer {
    margin-top: 0;
    &-content {
      margin-top: 0;
    }
  }
}
